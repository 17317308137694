import React from 'react';
import Tooltip from '@common/components/tooltip';

interface BadgeCountProps {
  count: number;
  title: string | React.ReactNode;
}

const BadgeCount = ({ count, title }: BadgeCountProps) => {
  return (
    <Tooltip title={title}>
      <div
        className="
          tw-bg-gray-200 tw-text-gray-700
          tw-rounded-full tw-px-1 tw-py-1 tw-text-sm tw-w-4 tw-h-4
          tw-inline-flex tw-flex-row tw-items-center tw-justify-center
          tw-ml-1
        "
      >
        +{count}
      </div>
    </Tooltip>
  );
};

export default BadgeCount;
