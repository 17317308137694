import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';
import { useAppSelector } from '@common/hooks';
import { useDidComponentMount } from '@hooks/use-did-component-mount';
import { usePredicateState } from '@common/hooks/use-predicate-state';
import { useQueryParam } from '@common/hooks/url';
import * as organisationSelectors from '@modules/organisation/selectors/organisation';

interface UsersFilterProps {
  match: any;
  setFilters: (predicates: any) => void;
  defaultNetworks?: any[];
}

const UsersFilter = ({ match, setFilters, defaultNetworks }: UsersFilterProps) => {
  const networks = useAppSelector(organisationSelectors.networks);
  const functions = useAppSelector(organisationSelectors.functions);
  const organisation = useAppSelector(organisationSelectors.selected);
  const { t } = useTranslation();
  const history = useHistory();

  const didMount = useDidComponentMount();

  const defaultSearchValue = useQueryParam('q');

  const [predicates, setPredicates] = usePredicateState({
    networks,
    functions,
    defaultNetworks,
  });

  useEffect(() => {
    if (!didMount) return;
    setFilters(predicates);
  }, [predicates]);

  const setFiltersToolbarSearch = useCallback((search: string) => {
    const currentPath = window.location.pathname;
    return search ? history.push(`${currentPath}?q=${search}`) : history.push(currentPath);
  }, [history]);

  const { filter } = match.params;

  let hidePredicatesFor = ['deleted', 'requests'];
  if (match.params.networkId) {
    hidePredicatesFor = [...hidePredicatesFor, 'active', 'inactive', 'not_logged_in', 'not_invited', 'requests'];
  }

  return (
    <FiltersToolbar
      predicates={hidePredicatesFor.includes(filter) ? [] : predicates}
      setPredicates={setPredicates}
      organisation={organisation}
      onSearch={(search: string) => setFiltersToolbarSearch(search)}
      searchPlaceholder={t('organisation:users_filter_search_placeholder')}
      defaultSearchValue={defaultSearchValue}
    />
  );
};

export default UsersFilter;
